<script setup lang="ts">
interface Props {
    modelValue?: string | number 
    placeholder?: string
    label?: string
    type?: string
    id?: string
    error?: string | null;
    required?: boolean
    disabledArrow?: boolean
    step?: string
    min?: string | number
}
const emits = defineEmits(["update:modelValue", "search", 'input'])
withDefaults(defineProps<Props>(), {
    step: "0.01",
    type: "text"
})

const updateValue = (event: any) => {
    emits('update:modelValue', event.target.value)
    emits('input', event)
}
</script>

<template>
    <label :for="id" class="inputGroup">
        <span v-if="label">{{ label }}<span v-if="required" class="text-error"> *</span></span>
        <input 
            class="custom-input"
            :class="[{inputError: error}, {disabledArrow: disabledArrow}]"
            :id="id"
            :type="type" 
            :value="modelValue"
            @input="updateValue"
            :placeholder="placeholder"
            :aria-label="placeholder"
            @keydown.enter="$emit('search')"
            :step="step"
            :min="min"
        />
        <span class="text-xs text-error" v-if="error">{{ error }}</span>
    </label>
</template>

<style scoped lang="scss">
.inputGroup {
    display: flex;
    gap: .2rem;
    flex-direction: column;
}
.inputError {
    border-color: var(--error-color);
}
</style>